
import { ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "TariffLevelSelect",
  props: {
    level_id: {
      type: Number,
      default: undefined,
    },
    office_id: {
      type: Array,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:level_id", "getCriterias"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const inputLevel = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isLevelRequired = (value) => {
      if (props.required && !value) {
        return t("rlevel");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "level",
      isLevelRequired
    );

    const selectLevel = (query) => {
      if (query !== "" && query !== undefined) {
        inputLevel.value.loading = true;
        setTimeout(() => {
          inputLevel.value.loading = false;
          inputLevel.value.options = inputLevel.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (inputLevel.value.options.length === 0) {
            ApiService.query("/api/tariff_level", {
              params: {
                per_page: 25,
                search: query,
                offices_id: JSON.stringify(props.office_id),
                cost_type_id:
                  route.name === "costTariffsCreate" ||
                  route.name === "costTariffsEdit"
                    ? "[3]"
                    : "[1]",
              },
            }).then((response: any) => {
              response.data.data.forEach((o) => {
                store.commit("addLevelTariff", o);
              });
              inputLevel.value.list = response.data.data;
              inputLevel.value.options = response.data.data;
            });
          }
        }, 200);
      } else if (query === undefined) {
        ApiService.query("/api/tariff_level", {
          params: {
            per_page: 25,
            search: query,
            offices_id: JSON.stringify(props.office_id),
            cost_type_id:
              route.name === "costTariffsCreate" ||
              route.name === "costTariffsEdit"
                ? "[3]"
                : "[1]",
          },
        }).then((response: any) => {
          response.data.data.forEach((o) => {
            store.commit("addLevelTariff", o);
          });
          inputLevel.value.list = response.data.data;
          inputLevel.value.options = response.data.data;
        });
      } else {
        inputLevel.value.options = [];
      }
    };

    watch(
      () => props.level_id,
      (first) => {
        element_id.value = first;
        const criterias = inputLevel.value.options.filter(
          (x) => x.id === first
        );
        if (criterias.length) {
          emit("getCriterias", criterias[0].criterias);
        } else {
          ApiService.get(`/api/tariff_level/${first}`).then((response: any) => {
            const item = {
              id: response[1].id,
              level: response[1].level,
              name: response[1].name,
              cost_type_id: response[1].cost_type_id,
              criteria: response[1].criterias,
              billing_concept_code: response[1].billing_concept_code.name,
            };
            inputLevel.value.list.push({ ...item });
            inputLevel.value.options.push({ ...item });
            emit("getCriterias", item.criteria);
          });
        }
      }
    );

    watch(
      () => props.office_id,
      (first) => {
        selectLevel(undefined);
      },
      { deep: true }
    );

    return {
      element_id,
      inputLevel,
      errorMessage,
      selectLevel,
    };
  },
};
